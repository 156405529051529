import printReportAction from '@/components/nsw-components/PrintReportsAction';
import DocumentNewVersionAction from '@/components/nsw-components/DocumentNewVersionAction';

const newFalForm = {
	id: 'newFalForm',
	selectionType: 'general', // [single|multiple|general]
	label: 'grid.imofalformsgeneral.newFalForm',
	functionality: 'INSERT_FAL_FORM_PORTCALL', // set the functionality if needed
	showInForm: true,
	checkAvailability: function (/*registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0] ? registries[0] : {};

		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-newFalFormAction-' + model.name + '-show', row);
	}
};

const getReportParameters = (model) => {
	switch (model) {
		case 'imofalform1':
			return [['GENERAL_DECLARATION', 'imofalformid', 'reports.label2.falForms.generalDeclaration']];
		case 'imofalform2':
			return [['CARGO_DECLARATION', 'imofalformid', 'reports.label2.falForms.cargoDeclaration']];
		case 'imofalform3':
			return [['STORE_DECLARATION', 'imofalformid', 'reports.label2.falForms.storeDeclaration']];
		case 'imofalform4':
			return [['EFFECT_DECLARATION', 'imofalformid', 'reports.label2.falForms.effectDeclaration']];
		case 'imofalform5':
			return [['CREW_LIST', 'imofalformid', 'reports.label2.falForms.crewList']];
		case 'imofalform6':
			return [
				['PASSENGER_LIST', 'imofalformid', 'reports.label2.falForms.passengerList'],
				['VEHICLES_LIST', 'imofalformid', 'reports.label2.falForms.vehicleList']
			];
		case 'imofalform7':
			return [['DANGEROUS_GOODS', 'imofalformid', 'reports.label2.falForms.dangerousGoods']];
		case 'driverlist':
			return [['DRIVER_LIST', 'imofalformid', 'reports.label2.falForms.driverlist']];

		default:
			return [];
	}
};

const getNewVersionParameters = (model) => {
	switch (model) {
		case 'imofalform1':
			return { imofalformtypeid: 1 };
		case 'imofalform2':
			return { imofalformtypeid: 2 };
		case 'imofalform3':
			return { imofalformtypeid: 3 };
		case 'imofalform4':
			return { imofalformtypeid: 4 };
		case 'imofalform5':
			return { imofalformtypeid: 5 };
		case 'imofalform6':
			return { imofalformtypeid: 6 };
		case 'imofalform7':
			return { imofalformtypeid: 7 };
		case 'driverlist':
			return { imofalformtypeid: 11 };
		default:
			return null;
	}
};

const createPrintReportAction = (model) => {
	const reportParametersList = getReportParameters(model);
	if (reportParametersList.length === 0) {
		return null;
	}
	return reportParametersList.map(([reportType, idAttributeName, actionLabel]) => ({
		...printReportAction.printReport(reportType, idAttributeName, actionLabel),
		checkAvailability: function (registries) {
			return registries.length > 0 && registries[0].model === model;
		}
	}));
};

const createNewVersionAction = (model) => {
	const params = getNewVersionParameters(model);
	if (!params) {
		return null;
	}
	return {
		...DocumentNewVersionAction.newVersion(params),

		checkAvailability: function (registries) {
			return registries.length > 0 && registries[0].model === model && registries[0].status != 'Draft' && registries[0].status != 'Invalid';
		}
	};
};

const models = ['imofalform1', 'imofalform2', 'imofalform3', 'imofalform4', 'imofalform5', 'imofalform6', 'imofalform7', 'driverlist']; // Añadir más modelos según sea necesario

const actions = models
	.flatMap((model) => {
		const printActions = createPrintReportAction(model) || [];
		const newVersionAction = createNewVersionAction(model);
		const allActions = newVersionAction ? [...printActions, newVersionAction] : printActions;
		if (allActions.length > 0) {
			allActions.forEach(() => console.log(`Acción creada para el modelo: ${model}`));
		}
		return allActions;
	})
	.filter((action) => action !== null);

export default {
	gridactions: [newFalForm, ...actions],
	formactions: [newFalForm]
};
