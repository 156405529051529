<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('grid.imofalformsgeneral.newFalFormmodal')"
			:modelName="modelName"
			:dialogName="newFalFormAction"
			:onOk="onOkNewFalForm"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<!-- ENTRYID -->
					<v-col cols="12">
						<pui-select
							:id="`imofalformsgeneral-type`"
							attach="imofalformsgeneral-type"
							:label="$t('grid.imofalformsgeneral.type')"
							toplabel
							clearable
							v-model="data.modalData"
							modelName="vmimofalformtypes"
							:modelFormMapping="{ imofalformtypeid: 'type' }"
							:itemsToSelect="[{ imofalformtypeid: data.modalData.type }]"
							:itemValue="['imofalformtypeid']"
							:itemText="formatItemText"
							:order="{ imofalformtypeid: 'asc' }"
						></pui-select>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.newVersion')"
			:modelName="modelName"
			:dialogName="newVersion"
			:widthDialog="800"
			:onOk="onOknewVersion"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`versionRemarks`"
							v-model="data.modalData.versionRemarks"
							:label="$t('modal.version_remarks')"
							:disabled="formDisabled"
							toplabel
							required
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'imofalformsgeneral-modals',
	data() {
		return {
			newFalFormAction: 'newFalFormAction',
			newVersion: 'newVersion',
			model: this.modelName,
			versionRemarks: '',
			modalData: {}
		};
	},
	mounted() {},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		formatItemText(item) {
			if (item.imofalformtypeid == 11) {
				return ` ${item.typedescription}`;
			} else {
				return `${item.imofalformtypeid} - ${item.typedescription}`;
			}
		},
		onOkNewFalForm(modalData) {
			let goto = '';
			switch (modalData.type) {
				case 1:
					goto = 'imofalform1menu';
					break;
				case 2:
					goto = 'imofalform2menu';
					break;
				case 3:
					goto = 'imofalform3menu';
					break;
				case 4:
					goto = 'imofalform4menu';
					break;
				case 5:
					goto = 'imofalform5menu';
					break;
				case 6:
					goto = 'imofalform6menu';
					break;
				case 7:
					goto = 'imofalform7menu';
					break;
				case 11:
					goto = 'driverlistmenu';
					break;
			}
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, 'portcalls');

			let portcall = btoa(`{"portcallid":${parentModel.pk.portcallid}}`);

			localStorage.setItem('tabFromAction', 'imofalformsgeneral');

			this.$router.push('/portcalls/update/' + portcall + '/' + goto + '/create/e30=/');
		},
		onOknewVersion(modalData) {
			const controller = '/documents/newVersion';
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('action.newVersion');
			let fal;
			switch (modalData.model) {
				case 'imofalform1':
					fal = 'imofalform1menu';
					break;
				case 'imofalform2':
					fal = 'imofalform2menu';
					break;
				case 'imofalform3':
					fal = 'imofalform3menu';
					break;
				case 'imofalform4':
					fal = 'imofalform4menu';
					break;
				case 'imofalform5':
					fal = 'imofalform5menu';
					break;
				case 'imofalform6':
					fal = 'imofalform6menu';
					break;
				case 'imofalform7':
					fal = 'imofalform7menu';
					break;
				case 'driverlist':
					fal = 'driverlistmenu';
					break;
				default:
					fal = this.modelName; // Puedes establecer un modelo por defecto en caso de que no coincida ningún caso
			}
			const params = {
				pk: modalData.imofalformid,
				model: fal,
				remarks: modalData.versionRemarks
			};
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					controller,
					params,
					() => {
						// Valid response, do code and close modal
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					null
				);
			});
		}
	}
};
</script>
